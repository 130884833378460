$creditcardprocessor-why-us-brief: #747474;

$cardprocessingoffers-default-link-color: #018484;
$cardprocessingoffers-default-checkbox-selected-bgcolor: #ecf5f4;

$cardprocessingoffers-default-button-bgcolor: #01847f;
$cardprocessingoffers-default-button-text-color: #fff;

$cardprocessingoffers-default-hover-button-bgcolor: #034749;
$cardprocessingoffers-default-hover-button-border-color: #000000;
$cardprocessingoffers-default-hover-button-text-color: #fff;

$slogan: #018484;
$bg1: #005c58;
$textColor: #fff;
$cardprocessingoffers-progress-color: #aaa;

:export {
    slogan: $slogan;
    primaryColor: $cardprocessingoffers-default-button-bgcolor;
    progressColor: $cardprocessingoffers-progress-color;
    whyUsBriefColor: $creditcardprocessor-why-us-brief;
    linkColorDefault: $cardprocessingoffers-default-link-color;
    buttonBgColorDefault: $cardprocessingoffers-default-button-bgcolor;
    whyUsBackgroundColor:$bg1
}
